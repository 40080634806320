
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import { api } from '../api'
import { TaskInfo, Task } from '../zzgen'
import { TaskListBlock } from '../components/TaskListBlock'

type TaskPageProps = {
}

export function TaskPage(props: TaskPageProps) {
    const { taskID } = useParams()
    const navigate = useNavigate()
    const [task, setTask] = useState<TaskInfo | null>(null)

    if (!taskID) {
        navigate(-1)
    }

    if (!/\d+/.test((taskID as string))) {
        navigate(-1)
    }

    const taskIDVal: number = +(taskID as string)

    const refreshTask = (taskIDVal: number) => {
        api.tasksTaskIDGet({
            taskID: taskIDVal
        }).then((res: TaskInfo) => {
            setTask(res)
        })
    }

    const deleteDep = async (depID: number) => {
        await api.depDelete({
            taskID: taskIDVal,
            depID: depID,
        })

        refreshTask(taskIDVal)
    }

    useEffect(() => {
        refreshTask(taskIDVal)
    }, [setTask, taskIDVal])

    if (!task) {
        return null
    }

    return <div>
        <h2>{task.task.title}</h2>
        {task.task.description !== "" && <p>{task.task.description}</p>}
        {task.task.children && task.task.children.length > 0 && <div>
            <h3>Подзадачи</h3>
            <TaskListBlock tasks={task.task.children} updateTasks={() => refreshTask(taskIDVal)} />
        </div>}

        {task.deps.length > 0 && <div>
            <h3>Зависимости</h3>
            <TaskListBlock
                tasks={task.deps}
                updateTasks={() => refreshTask(taskIDVal)}
                extraContextMenu={[{title: "Удалить зависимость", onClick: (task: Task) => {deleteDep(task.id)}}]}
                />
        </div>}
    </div>
}