/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskUpdateReq
 */
export interface TaskUpdateReq {
    /**
     * 
     * @type {string}
     * @memberof TaskUpdateReq
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskUpdateReq
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof TaskUpdateReq
     */
    startsAt?: Date | null;
}

/**
 * Check if a given object implements the TaskUpdateReq interface.
 */
export function instanceOfTaskUpdateReq(value: object): value is TaskUpdateReq {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function TaskUpdateReqFromJSON(json: any): TaskUpdateReq {
    return TaskUpdateReqFromJSONTyped(json, false);
}

export function TaskUpdateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskUpdateReq {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'startsAt': json['startsAt'] == null ? undefined : (new Date(json['startsAt'])),
    };
}

export function TaskUpdateReqToJSON(value?: TaskUpdateReq | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'startsAt': value['startsAt'] == null ? undefined : ((value['startsAt'] as any).toISOString()),
    };
}

