/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectCreateReq
 */
export interface ProjectCreateReq {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateReq
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateReq
     */
    sortOrder: number;
}

/**
 * Check if a given object implements the ProjectCreateReq interface.
 */
export function instanceOfProjectCreateReq(value: object): value is ProjectCreateReq {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('sortOrder' in value) || value['sortOrder'] === undefined) return false;
    return true;
}

export function ProjectCreateReqFromJSON(json: any): ProjectCreateReq {
    return ProjectCreateReqFromJSONTyped(json, false);
}

export function ProjectCreateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreateReq {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'sortOrder': json['sortOrder'],
    };
}

export function ProjectCreateReqToJSON(value?: ProjectCreateReq | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'sortOrder': value['sortOrder'],
    };
}

