/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthRegisterUidActivatePostRequest
 */
export interface AuthRegisterUidActivatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRegisterUidActivatePostRequest
     */
    code?: string;
}

/**
 * Check if a given object implements the AuthRegisterUidActivatePostRequest interface.
 */
export function instanceOfAuthRegisterUidActivatePostRequest(value: object): value is AuthRegisterUidActivatePostRequest {
    return true;
}

export function AuthRegisterUidActivatePostRequestFromJSON(json: any): AuthRegisterUidActivatePostRequest {
    return AuthRegisterUidActivatePostRequestFromJSONTyped(json, false);
}

export function AuthRegisterUidActivatePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthRegisterUidActivatePostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
    };
}

export function AuthRegisterUidActivatePostRequestToJSON(value?: AuthRegisterUidActivatePostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
    };
}

