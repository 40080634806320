/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
    /**
     * 
     * @type {string}
     * @memberof CurrentUser
     */
    readonly email: string;
}

/**
 * Check if a given object implements the CurrentUser interface.
 */
export function instanceOfCurrentUser(value: object): value is CurrentUser {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function CurrentUserFromJSON(json: any): CurrentUser {
    return CurrentUserFromJSONTyped(json, false);
}

export function CurrentUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUser {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function CurrentUserToJSON(value?: Omit<CurrentUser, 'email'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

