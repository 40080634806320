/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthRegisterPost200Response
 */
export interface AuthRegisterPost200Response {
    /**
     * 
     * @type {number}
     * @memberof AuthRegisterPost200Response
     */
    id?: number;
}

/**
 * Check if a given object implements the AuthRegisterPost200Response interface.
 */
export function instanceOfAuthRegisterPost200Response(value: object): value is AuthRegisterPost200Response {
    return true;
}

export function AuthRegisterPost200ResponseFromJSON(json: any): AuthRegisterPost200Response {
    return AuthRegisterPost200ResponseFromJSONTyped(json, false);
}

export function AuthRegisterPost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthRegisterPost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function AuthRegisterPost200ResponseToJSON(value?: AuthRegisterPost200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

