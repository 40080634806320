/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskCreateResp
 */
export interface TaskCreateResp {
    /**
     * 
     * @type {number}
     * @memberof TaskCreateResp
     */
    id?: number;
}

/**
 * Check if a given object implements the TaskCreateResp interface.
 */
export function instanceOfTaskCreateResp(value: object): value is TaskCreateResp {
    return true;
}

export function TaskCreateRespFromJSON(json: any): TaskCreateResp {
    return TaskCreateRespFromJSONTyped(json, false);
}

export function TaskCreateRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskCreateResp {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function TaskCreateRespToJSON(value?: TaskCreateResp | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

