/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthLoginPost200Response
 */
export interface AuthLoginPost200Response {
    /**
     * 
     * @type {number}
     * @memberof AuthLoginPost200Response
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginPost200Response
     */
    readonly email: string;
}

/**
 * Check if a given object implements the AuthLoginPost200Response interface.
 */
export function instanceOfAuthLoginPost200Response(value: object): value is AuthLoginPost200Response {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function AuthLoginPost200ResponseFromJSON(json: any): AuthLoginPost200Response {
    return AuthLoginPost200ResponseFromJSONTyped(json, false);
}

export function AuthLoginPost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthLoginPost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
    };
}

export function AuthLoginPost200ResponseToJSON(value?: Omit<AuthLoginPost200Response, 'id'|'email'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

